import { useNavigate, useParams } from 'react-router-dom'
import { useLocalStore } from '../libs/store'
import Loader from '../components/shared/Loader'
import { useEffect } from 'react'
import { db } from '@flowby/shared-firebase'

export default function ConvertManualPage() {
  const navigate = useNavigate()
  const params = useParams()
  const store = params.store as string
  const queue = params.queue as string
  const queuerId = params.queuerId as string

  const setQueueNumberId = useLocalStore(state => state.setQueueNumberId)

  useEffect(() => {
    setQueueNumberId(store, queue, queuerId)
    db.convertManualQueueNumber(store, queue, queuerId)
    navigate(`/${store}/q/${queue}`)
  }, [])

  return (
    <Loader />
  )
}
