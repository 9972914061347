import create from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import produce from 'immer'

type LocalStoreState = {
  queueNumbers: { [key: string]: string | undefined }
  setQueueNumberId: (store: string, queue: string, val: string | undefined) => void
}

const useLocalStore = create<LocalStoreState>()(
  persist(
    set => ({
      queueNumbers: {},
      setQueueNumberId: (store, queue, val) =>
        set(
          produce(state => {
            if (val) {
              state.queueNumbers[`${store}|${queue}`] = val
            } else {
              delete state.queueNumbers[`${store}|${queue}`]
            }
          }),
        )
    }),
    {
      name: 'flowby-storage',
    },
  ),
)

type SessionStoreState = {
  userLanguage: string
  setUserLanguage: (val: string) => void
}

const useSessionStore = create<SessionStoreState>()(
  persist(
    set => ({
      userLanguage: '',
      setUserLanguage: val => set({ userLanguage: val }),
    }),
    {
      name: 'flowby-session',
      storage: createJSONStorage(() => sessionStorage)
    },
  ),
)


export {
  useLocalStore,
  useSessionStore,
}
