import { useState } from 'react'
import { Flex, Box, Stack, Grid, GridItem, Heading, Text, Container, Image } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { GroupData, QueueData } from '@flowby/shared-firebase'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import QueueStatusBadge from '../shared/QueueStatusBadge'
import Language from '../shared/Language'

export default function GroupView({
  store,
  groupData,
  queuesData,
  kiosk,
}: {
  store: string
  groupData: GroupData
  queuesData: QueueData[]
  kiosk: boolean
}) {
  const { t } = useTranslation()
  const [logoError, setLogoError] = useState(false)
  const fromKiosk = kiosk ? '?kiosk=true' : ''
  const queuesClosed = !queuesData.some((queueData) => {
    if (groupData.queues.includes(queueData.shortName)) {
      return queueData.state.status === 'open'
    }
  })

  return (
    <Flex id="group-queue-selector" direction="column" minH={'95vh'}>
      <Grid py={2} px={2} templateRows="repeat(1, 1fr)" templateColumns="repeat(4, 1fr)" gap={2}>
        <GridItem colSpan={1} pt={1} pl={1} zIndex={1500}>
          <Language store={store} />
        </GridItem>
        <GridItem colSpan={2} mb={-2} pt={1}>
          <Box position="relative" w="100%" h="100%" textAlign="center" alignItems="center">
            {!logoError && (
              <Image
                maxH={43}
                margin="auto"
                fit="contain"
                src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === 'dev' ? 'flowby-io-dev' : 'flowby-io'}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
                onError={() => setLogoError(true)}
              />
            )}
          </Box>
        </GridItem>
        <GridItem colSpan={1}></GridItem>
      </Grid>
      <Container py={2}>
        <Stack py={{ base: 4, md: 12 }}>
          {!queuesClosed && (
            <>
              <Heading textAlign="center">{t('choose-queue')}</Heading>
              <Text textAlign="center">{t('choose-queue-instruction')}</Text>
              {queuesData.map(q => {
                if (groupData.queues.includes(q.shortName) && q.state.status === 'open') {
                  return (
                    <Box
                      key={q.shortName}
                      id={`group-queue-selector-queue-${q.shortName}`}
                      {...(q.state.status === 'open'
                        ? { as: RouterLink, to: `/${store}/q/${q.shortName}${fromKiosk}` }
                        : { to: '' })}
                    >
                      <Grid
                        templateColumns="repeat(6, 1fr)"
                        p={5}
                        shadow="md"
                        borderWidth="1px"
                        bg="white"
                        borderRadius="0.375rem"
                        _hover={{ textDecoration: 'none' }}
                      >
                        <GridItem colSpan={5} display="flex" alignItems="center">
                          <Heading size="md">
                            {q.displayName}
                            <QueueStatusBadge status={q.state.status} />
                          </Heading>
                        </GridItem>
                        <GridItem display="flex" colSpan={1} justifyContent="center" alignItems="center">
                          {q.state.status === 'open' ? <ArrowForwardIcon w={8} h={8} /> : null}
                        </GridItem>
                      </Grid>
                    </Box>
                  )
                }
                return null
              })}
            </>
          )}
          {queuesClosed && (
            <Box pt={20} textAlign="center">
              <Heading size="md" pb={2}>
                {t('queue-closed')}
              </Heading>
              <Text fontSize="xl">{t('queue-closed-try-later')}</Text>
            </Box>
          )}
        </Stack>
      </Container>
    </Flex>
  )
}
