import QueueView from '../components/Queue/QueueView'
import { db, QueueData } from '@flowby/shared-firebase'
import Error from '../components/shared/Error'
import Loader from '../components/shared/Loader'
import { useCollData, useDocData } from '../libs/firebaseHooks'

export default function QueuePageContainer({
  store,
  queue,
  queueData,
  queueNumberId,
}: {
  store: string
  queue: string
  queueData: QueueData
  queueNumberId: string
}) {
  const [queueNumberData, queueNumberDataLoading] =
    useDocData(db.getQueueNumberRef(store, queue, queueNumberId), [store, queue, queueNumberId])
  const [queueNumbersData, queueNumbersDataLoading] = useCollData(db.getQueueNumbersRef(store, queue), [store, queue])

  // if creating queue number
  if (queueNumberDataLoading || queueNumbersDataLoading) {
    return <Loader />
  }

  // if there still is no queue number, should not happen
  if (!queueNumberId || !queueNumbersData) {
    return <Error />
  }

  const queueNumbersBefore =
    queueNumberData ? queueNumbersData.filter(qnr => qnr.queueNumber < queueNumberData.queueNumber).length : 0

  return <QueueView
    store={store}
    queue={queue}
    queueData={queueData}
    queueNumberId={queueNumberId}
    queueNumberData={queueNumberData}
    queueNumbersBefore={queueNumbersBefore}
  />
}
