import { db } from '@flowby/shared-firebase'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import Error from '../components/shared/Error'
import Loader from '../components/shared/Loader'
import Thanks from '../components/Thanks'
import { useDocData } from '../libs/firebaseHooks'
import { useLocalStore } from '../libs/store'

export default function ThanksPage() {
  const params = useParams()
  const store = params.store as string
  const queue = params.queue as string
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { t } = useTranslation()
  const doesNotExistTranslation = t('does-not-exist')
  const leaver = Boolean(queryParams.get('leaver') && queryParams.get('leaver') === 'true')
  const queueNumber = (queryParams.get('queue_number') as string | null) || undefined
  const queueNumberId = (queryParams.get('queue_number_id') as string | null) || undefined
  const queueClosed = queryParams.get('queue_closed') === 'true' || false
  const keepMyTurnOnThanks = queryParams.get('keep_my_turn') === 'true' || false

  // remove local queue number since it has already been used
  const setQueueNumberId = useLocalStore(state => state.setQueueNumberId)
  useEffect(() => {
    setQueueNumberId(store, queue, undefined)
  })

  const [queueData, queueDataLoading] = useDocData(db.getQueueRef(store, queue), [store, queue])

  // if data loading
  if (queueDataLoading) {
    return <Loader />
  }

  // if data not found or error
  if (!queueData) {
    return <Error text={doesNotExistTranslation} />
  }

  return (
    <Thanks
      store={store}
      queue={queue}
      queueNumber={queueNumber}
      queueData={queueData}
      queueNumberId={queueNumberId}
      leaver={leaver}
      queueClosed={queueClosed}
      keepMyTurnOnThanks={keepMyTurnOnThanks}
    />
  )
}
