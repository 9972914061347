import 'core-js'
import 'proxy-polyfill'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import StatusPage from './pages/StatusPage'
import QueuePage from './pages/QueuePage'
import ThanksPage from './pages/ThanksPage'
import GroupPage from './pages/GroupPage'
import IndexPage from './pages/IndexPage'
import NotFound from './pages/NotFoundPage'
import GroupKioskPage from './pages/GroupKioskPage'
import QueueKioskPage from './pages/QueueKioskPage'
import ConvertManualPage from './pages/ConvertManualPage'

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<IndexPage />} />
          <Route path=":store">
            <Route path="status" element={<StatusPage />} />
            <Route path="q">
              <Route path=":queue">
                <Route index element={<QueuePage />} />
                <Route path="thanks" element={<ThanksPage />} />
                <Route path="kiosk" element={<QueueKioskPage />} />
                <Route path="c">
                  <Route path=":queuerId">
                    <Route index element={<ConvertManualPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="g">
              <Route path=":group">
                <Route index element={<GroupPage />} />
                <Route path="kiosk" element={<GroupKioskPage />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
)
