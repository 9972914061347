import { useEffect, useState } from 'react'
import { Box, Stack, Container, Text, Button, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export default function KioskManualNumber({
  manualQueueNumber,
  onFinish,
}: {
  manualQueueNumber: number | null
  onFinish: () => void
}) {
  const { t } = useTranslation()
  const [timeLeft, setTimeLeft] = useState(20)

  useEffect(() => {
    if (timeLeft <= 0) {
      onFinish()
      return
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  return (
    <Container data-testid="kiosk-manual-number" mb={2}>
      <Stack textAlign="center" align="center" py={{ base: 4 }} spacing={4}>
        <Heading fontSize="5xl">{t('your-queue-number')}</Heading>
        <Box bgColor="yellow.300" borderRadius="0.375rem" px={8}>
          <Text
            data-testid="kiosk-manual-number-text"
            fontWeight="bold"
            fontSize="11rem"
          >
            {manualQueueNumber ? manualQueueNumber : '...'}
          </Text>
        </Box>
        <Text fontWeight="bold" fontSize="4xl">
          <span style={{ textDecoration: 'underline' }}>{t('kiosk-no-printed-queue-number')}</span>
          <br />
          {t('kiosk-memorize-manual-number')}
        </Text>
        <Button id="kiosk-manual-number-close" height={16} fontSize={22} pr={8} pl={8} onClick={onFinish}>
          {t('kiosk-close-manual-number-popup')}
        </Button>
        <Text fontSize="2xl">{timeLeft <= 10 && `${t('kiosk-manual-number-auto-close')} ${timeLeft}`}</Text>
      </Stack>
    </Container>
  )
}
