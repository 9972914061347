import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useLocalStore } from '../libs/store'
import QueuePageContainer from './QueuePageContainer'
import { DataFields, db, PiiCustomData } from '@flowby/shared-firebase'
import Error from '../components/shared/Error'
import Loader from '../components/shared/Loader'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDocData } from '../libs/firebaseHooks'
import DataInput from '../components/Queue/DataInput'

export default function QueuePage() {
  const { t } = useTranslation()
  const doesNotExistTranslation = t('does-not-exist')
  const params = useParams()
  const location = useLocation()
  const store = params.store as string
  const queue = params.queue as string
  const queryParams = new URLSearchParams(location.search)
  const kiosk = (queryParams.get('kiosk') as boolean | null) || false
  const [dataInput, setDataInput] = useState<null | PiiCustomData['data']>(null)

  const localQueueNumbers = useLocalStore(state => state.queueNumbers)
  const queueNumberId = localQueueNumbers && localQueueNumbers[`${store}|${queue}`]
  const [queueData, queueDataLoading] = useDocData(db.getQueueRef(store, queue), [store, queue])
  const setQueueNumberId = useLocalStore(state => state.setQueueNumberId)
  const [loadingCreateQueueNumber, setLoadingCreateQueueNumber] = useState(false)

  const hasCustomDataFields =
    queueData && queueData.customDataFields && Object.keys(queueData.customDataFields).length > 0

  useEffect(() => {
    if (
      queueData && queueData.state.status === 'open' &&
      (!hasCustomDataFields || dataInput)
    ) {
      if (!queueNumberId && !loadingCreateQueueNumber) {
        setLoadingCreateQueueNumber(true)
        db.createQueueNumber(store, queue, false, kiosk).then(queueNumber => {
          setQueueNumberId(store, queue, queueNumber.id)
          setLoadingCreateQueueNumber(false)
          // if no values set user has skipped the form
          // and we should not store anything as this will count as data input in analytics
          if (dataInput && !Object.values(dataInput).every(value => value === '')) {
            db.storeCustomDataInput(queueNumber.id, store, queue, dataInput)
          }
        })
      }
    }
  }, [queueData, queueNumberId, dataInput])

  // if data loading
  if (queueDataLoading || loadingCreateQueueNumber) {
    return <Loader />
  }

  // if data not found or error
  if (!queueData) {
    return <Error text={doesNotExistTranslation} />
  }

  // if queue closed
  if (
    !queueData.state ||
    queueData.state.status === 'closed' ||
    (queueData.state.status === 'closing' && !queueNumberId)
  ) {
    return <Navigate to={`/${store}/q/${queue}/thanks?queue_closed=true`} />
  }

  // if input required before entering queue
  if (
    !queueNumberId &&
    hasCustomDataFields &&
    !dataInput
  ) {
    return <DataInput
      store={store}
      queueData={queueData}
      customDataFields={queueData.customDataFields as DataFields}
      setInputData={setDataInput}
    />
  }

  // if data loading, needs to be handled separately here otherwise queue closed will never show
  if (!queueNumberId) {
    return <Loader />
  }

  return (
    <QueuePageContainer store={store} queue={queue} queueData={queueData} queueNumberId={queueNumberId} />
  )
}
